import React from "react";

import Locations from "./Locations";


function Home() {
  
  return (
    
    <main>
      <Locations/>
     

    </main>
  );
}



export default Home;