import { useState, useEffect } from "react";
import forecast from '../api/forecast.js';

const useForecastFetch = () => {
  const [forecastData, setForecastData] = useState({
    lat: "",
    long: "",
    weather: [],
  });

  useEffect(() => {
    if (forecastData.lat !== "" &&  forecastData.long !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            const res = await forecast.get(``,{ params: { latitude: `${forecastData.lat}` , longitude: `${forecastData.long}`, current_weather : "true"} });
            setForecastData({ ...forecastData, weather: res.data });
            console.log('res.data ->',res.data);
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [forecastData.lat, forecastData.long] );

  return { forecastData, setForecastData };
};

export default useForecastFetch;