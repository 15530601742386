import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useFetch from "../hooks/useFetch";
import { useForm, Controller } from "react-hook-form";

function GetLocations() {
  const { data, setData } = useFetch();


  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });
  const myHelper = {
    location: {
      required: "Search field is Required",
      pattern: "Invalid search field"
    }
  };
  console.count("app rerender");

  const handleOnSubmit = (evt) => {
    setData({ ...data, slug: evt.location });
    console.log(evt);
  };


  return (
    <div className="App">
      <br/>
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        {/* <input
          type="search"
          placeholder="Type your city name"
          value={data.slug}
          onChange={(e) => setData({ ...data, slug: e.target.value })}
        /> */}
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="location"
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="search"
                  fullWidth
                  label="Type location to search"
                  error={error !== undefined}
                  helperText={error ? myHelper.location[error.type] : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Button type="submit">Submit</Button>
          </Grid>
        </Grid>
        <br />
        {data.results.length > 0 ? <Members rows={data.results} /> : null}
      </Box>
    </div >
  );
}

export function Members({ rows }) {
  const url = '/forecast';
  return (

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell >Country</TableCell>
            <TableCell >admin1</TableCell>
            <TableCell >admin2</TableCell>
            <TableCell >Latitude</TableCell>
            <TableCell >Longitude</TableCell>
            <TableCell >Elevation</TableCell>
            <TableCell >Population</TableCell>

            <TableCell >Feature Code</TableCell>
            <TableCell >timezone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Link href={`${url}/${row.latitude}/${row.longitude}`} >{row.name}</Link>
              </TableCell>
              <TableCell text>{row.country}</TableCell>
              <TableCell text>{row.admin1}</TableCell>
              <TableCell text>{row.admin2}</TableCell>
              <TableCell numeric>{row.latitude}</TableCell>
              <TableCell numeric>{row.longitude}</TableCell>
              <TableCell numeric>{row.elevation}</TableCell>
              <TableCell numeric>{row.population}</TableCell>
              <TableCell text>{row.feature_code}</TableCell>
              <TableCell text>{row.timezone}</TableCell>
            </TableRow>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default GetLocations;