import { useState, useEffect } from "react";
import openMateoGeo from '../api/open-meteo.js';

const useFetch = () => {
  const [data, setData] = useState({
    slug: "",
    results: [],
  });

  useEffect(() => {
    if (data.slug !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            if (data.slug.length >= 3) {
              const res = await openMateoGeo.get(``, { params: { name: `${data.slug}`, count: 100 } });
              setData({ ...data, results: res.data.results });
              console.log(res.data.results);
            }
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [data.slug]);

  return { data, setData };
};

export default useFetch;