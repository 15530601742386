//https://codesandbox.io/s/rz94tr?file=/demo.tsx

import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import useFetch from "../hooks/accuWeatherLocationFetch";
import { useForm, Controller } from "react-hook-form";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface Data {
    Name: string;
    Country: string;
    admin1: string;
    admin2: string;
    Latitude: string;
    Longitude: string;
    Elevation: number;
    Population: number;
    FeatureCode: string;
    Timezone: string;

}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'Id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
    },
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'Loc Name',
    },
    {
        id: 'State',
        numeric: false,
        disablePadding: false,
        label: 'State Name',
    },
    {
        id: 'Region',
        numeric: false,
        disablePadding: false,
        label: 'Region',
    },
    {
        id: 'admin2',
        numeric: false,
        disablePadding: false,
        label: 'Admin2',
    },
    {
        id: 'Latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },
    {
        id: 'Longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },
    {
        id: 'Elevation',
        numeric: true,
        disablePadding: false,
        label: 'Elevation',
    },
    {
        id: 'Type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'FeatureCode',
        numeric: false,
        disablePadding: false,
        label: 'FeatureCode',
    },
    {
        id: 'Timezone',
        numeric: false,
        disablePadding: false,
        label: 'Timezone',
    },

];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Weather Locations
                </Typography>
            )}
            
        </Toolbar>
    );
}
export default function Locations() {

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });
    const myHelper = {
        location: {
            required: "Search field is Required",
            pattern: "Invalid search field"
        }
    };
    console.count("app rerender");
    const handleOnSubmit = (evt) => {
        setData({ ...data, locationName: evt.location });
        console.log(evt);
    };
    const { data, setData } = useFetch();
    return (
        <div className="App">
            <br />
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="location"
                            defaultValue=""
                            rules={{
                                required: true
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    type="search"
                                    fullWidth
                                    label="Type location to search"
                                    error={error !== undefined}
                                    helperText={error ? myHelper.location[error.type] : ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button type="submit">Submit</Button>
                    </Grid>
                </Grid>
                <br />
                {data.results.length > 0 ? <EnhancedTable rows={data.results} /> : null}
            </Box>
        </div >
    );
}
export function EnhancedTable({ rows }) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Name');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows. sort(getComparator(order, orderBy)).slice()
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            key={row.Key}
                                            selected={isItemSelected}
                                        >
                                            <TableCell >
                                                {row.Key}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.EnglishName}</TableCell>
                                            <TableCell>{row.AdministrativeArea?.EnglishName}</TableCell>
                                            <TableCell>{row.Region.EnglishName}</TableCell>
                                            <TableCell>{row.admin2}</TableCell>
                                            <TableCell>{row.GeoPosition?.Latitude}</TableCell>
                                            <TableCell>{row.GeoPosition?.Longitude}</TableCell>
                                            <TableCell>{row.GeoPosition?.Elevation?.Metric?.Value}</TableCell>
                                            <TableCell>{row.Type}</TableCell>
                                            <TableCell>{row.Region?.EnglishName}</TableCell>
                                            <TableCell>{row.TimeZone.Name} / GMT {row.TimeZone.GmtOffset}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}

