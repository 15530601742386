import { useState, useEffect } from "react";
import accuWeatherLocation from '../api/accuWeatherLocation.js';

const useFetch = () => {
  const [data, setData] = useState({
    locationName: "",
    results: [],
  });

  useEffect(() => {
    if (data.locationName !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            if (data.locationName.length >= 3) {
              const res = await accuWeatherLocation.get(``, { params: { q: `${data.locationName}` } });
              setData({ ...data, results: res.data });
              console.log(res.data);
            }
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [data.locationName]);

  return { data, setData };
};

export default useFetch;