import React from "react";

import useFetch from "../hooks/forecastUseFetch.js";
import { useParams } from "react-router-dom";
import '../App2.css';
import { parseISO, format } from 'date-fns'

function Forecast() {
  console.count("app rerender");

  let { lat, long } = useParams();
  console.log('Forecast', lat, long);

  const { forecastData } = useFetch();
  forecastData.lat = lat;
  forecastData.long = long;

  return (

    <main>

      <br />
      {(forecastData !== null && forecastData.weather.generationtime_ms > 0) ? <Members weather={forecastData} /> : null}

    </main>
  );
}

export function Members({ weather }) {
  const arr = [
    { id: 1, country: 'Mainly clear ' },
    { id: 2, country: 'Partly cloudy ' },
    { id: 3, country: 'Overcast' },
    { id: 45, country: 'Fog and depositing rime fog ' },
    { id: 48, country: 'Fog and depositing rime fog' },
    { id: 51, country: 'Drizzle: Light intensity' },
    { id: 53, country: 'Drizzle:  moderate intensity' },
    { id: 55, country: 'Drizzle:  Dense intensity ' },
    { id: 56, country: 'Freezing Drizzle: Light intensity ' },
    { id: 57, country: 'Freezing Drizzle: Dense intensity ' },
    { id: 61, country: 'Rain: Slight intensity' },
    { id: 63, country: 'Rain: moderate  intensity' },
    { id: 65, country: 'Rain:  heavy intensity ' },
    { id: 66, country: 'Freezing Rain: Light intensity' },
    { id: 67, country: 'Freezing Rain: Heavy intensity' },
    { id: 71, country: 'Snow fall: Slight ' },
    { id: 73, country: 'Snow fall:  moderate' },
    { id: 75, country: 'Snow fall:  heavy intensity ' },
    { id: 77, country: 'Snow grains ' },
    { id: 80, country: 'Rain showers: Slight' },
    { id: 81, country: 'Rain showers: moderate ' },
    { id: 82, country: 'Rain showers: and violent ' },
    { id: 85, country: 'Snow showers slight and heavy' },
    { id: 86, country: 'Snow showers slight and heavy' },
    { id: 95, country: 'Thunderstorm: Slight or moderate' },
    { id: 96, country: 'Thunderstorm with slight and heavy hail' },
    { id: 99, country: 'Thunderstorm with slight and heavy hail ' }
  ];

  return (
    <div className="two-column-page-content ">
      <div className="page-column-1">
        <div className="page-content content-module">


          <a className="cur-con-weather-card card-module  content-module lbar-panel" href="#" />
          <div className="cur-con-weather-card__body">
            <div className="cur-con-weather-card__panel">
              <h2 className="cur-con-weather-card__title">
                Current Weather
              </h2>
              <p className="cur-con-weather-card__subtitle">
                {format(parseISO(weather.weather.current_weather?.time), 'HH:MM')}
              </p>
              <div className="forecast-container">
                {/* <svg className="weather-icon" data-src="/images/weathericons/6.svg" viewBox="0 0 288 288" width="88" height="88"><g strokeWidth="9.778" fill="none" fillRule="evenodd"><path d="M100 49v34.222M46.222 136.658H12M61.916 98.573l-24.2-24.2M128.209 109.427a39.111 39.111 0 1 0-53.778 56.71" stroke="#FF8700"></path><path d="M276 183.102a56.222 56.222 0 0 0-56.222-56.222h-9.534c-17.963-21.15-46.919-29.36-73.31-20.788-26.391 8.572-44.996 32.23-47.103 59.9H78.05c-20.25 0-36.667 16.415-36.667 36.666 0 20.25 16.416 36.666 36.667 36.666h141.778c31.031-.027 56.173-25.19 56.173-56.222Z" stroke="#BABABA"></path></g></svg> */}
                <div className="temp-container">
                  <div className="temp">{weather.weather.current_weather?.temperature}°<span className="after-temp">C</span></div>
                  <div className="real-feel">
                    {/* RealFeel®
                    17° */}
                  </div>
                </div>
              </div>
            </div>
            <div className="cur-con-weather-card__panel details-container">
              <div className="spaced-content detail">
                {/* <span className="label">RealFeel Shade™</span> */}
                {/* <span className="value">16°</span> */}
              </div>
              <div className="spaced-content detail">
                {/* <span className="label">Air Quality</span> */}
                {/* <span className="value" >Excellent</span> */}
              </div>
              <div className="spaced-content detail">
                <span className="label">Wind</span>
                <span className="value">{weather.weather.current_weather?.windspeed} km/h</span>
              </div>
              <div className="spaced-content detail">
                <span className="label">Wind Gusts</span>
                <span className="value">
                  {arr.find(cur => cur.id == weather.weather.current_weather?.weathercode).country}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
        <h5>Temperature: {weather.weather.current_weather?.temperature}</h5>
        <p>Current: {weather.weather.current_weather?.temperature}</p>
        <p>windspeed :  {weather.weather.current_weather?.windspeed}</p>
        <p>winddirection :  {weather.weather.current_weather?.winddirection}</p>
        <p>weathercode :  {weather.weather.current_weather?.weathercode}</p>
        <p>time :  {weather.weather.current_weather?.time}</p>

      </div> */}

      </div>
    </div>
  );
}
export default Forecast;