import './App.css';
import Locations from "./components/Locations";
import Locations2 from "./components/Locations.tsx";
import Forecast from "./components/Forecast";
import Menu from "./components/Menu.tsx";
import Home from './components/Home'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {

  return (
    <main>
    <Router>
      <Menu />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/locations2" element={<Locations2 />} />
        <Route path="/forecast/:lat/:long" component={Forecast} element={<Forecast/>}/>
      </Routes>
    </Router>

      

    </main>


  );
}

export default App;
